require("./jquery.spincrement");
require("bootstrap");
require("../scss/styles.scss");
require("swiper/dist/css/swiper.min.css");
require("inputmask/dist/jquery.inputmask.bundle");
import maskInput from 'vanilla-text-mask/dist/vanillaTextMask.js'
import emailMask from 'text-mask-addons/dist/emailMask';
require("waypoints/lib/jquery.waypoints.js");
const platform = require("platform");

(function ($) {
    $.fn.serializeJSON = function () {

        const o = {};
        const a = this.serializeArray();
        $.each(a, function () {
            if (o[this.name]) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return JSON.stringify(o);
    };
})(jQuery);

// Кнопка - "Наверх"
class CUpButton {
    init() {
        this.$upButton = $(".up-button");
        if (this.$upButton.length) {
            this.$upButton.on("click", $.proxy(this, "scrollToTop"));
            $(window).on("scroll", $.proxy(this, "toggleClassVisible"));
        }
    }

    toggleClassVisible() {
        if ($(window).scrollTop() >= 200) {
            this.$upButton
                .addClass("up-button--visible")
                .removeClass("up-button--invisible");
        } else {
            this.$upButton
                .addClass("up-button--invisible")
                .removeClass("up-button--visible");
        }
    }

    scrollToTop() {
        $("html, body").animate({scrollTop: 0}, 500);
        return false;
    }
}

const cUpButton = new CUpButton();
cUpButton.init();

// Модальное окно
class CModalWindow {
    init() {
        this.$buttonShowModal = $(".js-request-call");

        if (this.$buttonShowModal.length) {
            this.$popupWrapper = $(".popup-wrapper");
            this.$lockPage = $(".lock-page");
            this.$closeButton = $(".close-button");
            this.$buttonShowModal.on("click", $.proxy(this, "toggleClassShow"));
            this.$closeButton.on("click", $.proxy(this, "closeModal"));

            $(window).on("keydown", event => {
                const ESC_CODE = 27;
                if (event.keyCode === ESC_CODE) {
                    this.closeModal();
                }
            });
        }
    }

    toggleClassShow() {
        this.$popupWrapper.toggleClass("is-visible");
        this.$lockPage.toggleClass("is-active");
    }

    closeModal(event) {
        this.$popupWrapper.removeClass("is-visible");
        this.$lockPage.removeClass("is-active");
    }
}

const cModalWindow = new CModalWindow();
cModalWindow.init();

// Аккордеоны
class CAccordion {
    init() {
        this.accordionBox = $(".accordion-box");

        if (this.accordionBox.length) {
            this.accordionButton = this.accordionBox.find(".acc-btn");
            this.accordionBlock = this.accordionBox.find(".block");
            this.accordionContent = this.accordionBox.find(".acc-content");
            this.accordionButton.on("click", $.proxy(this, "toggleClassOpenClose"));
        }
    }

    toggleClassOpenClose(event) {
        const $accordionSelectButton = $(event.currentTarget);
        const $accordionBlock = $accordionSelectButton.closest(".block");
        const $accordionContent = $accordionSelectButton.next(".acc-content");

        if (
            !$accordionSelectButton.hasClass("active") &&
            !$accordionBlock.hasClass("active-block") &&
            !$accordionContent.hasClass("active-content")
        ) {
            this.accordionButton.removeClass("active");
            this.accordionBlock.removeClass("active-block");
            this.accordionContent.removeClass("active-content");
            this.accordionContent.slideUp(300);
            $accordionSelectButton.addClass("active");
            $accordionBlock.addClass("active-block");
            $accordionContent.addClass("active-content");
            $accordionContent.slideDown(300);
        }
    }
}

const cAccordion = new CAccordion();
cAccordion.init();

// Фиксированнная навигация на странице - "Партнеры"
class CNavBar {
    init() {
        this.$navBar = $(".nav-bar");
        if (this.$navBar.length) {
            this.$sideBarWrapper = $(".l-page__sidebar--left");
            this.$feedbackBlock = $(".block-feedback");
            this.$navLink = this.$navBar.find(".nav-link");
            $(window).on("scroll", $.proxy(this, "toggleFixed"));
            this.$navLink.on("click", $.proxy(this, "scrollToSection"));
        }
    }

    toggleFixed() {
        if ($(window).scrollTop() >= this.$navBar.offset().top) {
            this.$navBar.addClass("is-fixed");
            this.$navBar.removeClass("is-bottom");
        }

        if ($(window).scrollTop() < this.$sideBarWrapper.offset().top) {
            this.$navBar.removeClass("is-fixed");
        }

        if (
            $(window).scrollTop() >
            this.$feedbackBlock.offset().top - this.$navBar.height() * 2
        ) {
            this.$navBar.addClass("is-bottom");
        }
    }

    scrollToSection(event) {
        const $navLinkHref = $(event.currentTarget).attr("href");
        const $navLinkId = $navLinkHref.replace("#", "");
        const $wpZone = $(".wp-zone");

        const $offsetTopWpZone = $wpZone
            .filter((i, elem) => {
                return $(elem).attr("id") === $navLinkId;
            })
            .offset().top;

        $("html, body").animate({scrollTop: $offsetTopWpZone}, 500);
    }
}

const cNavBar = new CNavBar();
cNavBar.init();

// Блок с анимированными ключевыми показателями (цифры на главной странице)
class CKeyIndicatorAnimation {
    init() {
        this.$blockKeyIndicators = $(".block-key-indicators");
        this.$keyIndicator = this.$blockKeyIndicators.find(".spincrement");
        this.show = true;

        if (this.$blockKeyIndicators.length) {
            $(window).on("scroll load resize", () => {
                this.indicatorAnimationStart();
            });
        }
    }

    indicatorAnimationStart() {
        if (!this.show) return false;
        const $scrollTop = $(document).scrollTop();
        const $windowHeight = $(window).height();
        const $scrollTopAndWindowHeight = $scrollTop + $windowHeight;
        const $offsetTopKeyIndicators = this.$blockKeyIndicators.offset().top;
        const $heightKeyIndicators = this.$blockKeyIndicators.height();

        if (
            $scrollTopAndWindowHeight > $offsetTopKeyIndicators &&
            $scrollTop - $offsetTopKeyIndicators < $heightKeyIndicators
        ) {
            this.$keyIndicator.spincrement({
                thousandSeparator: "",
                duration: 2000
            });
            this.show = false;
        }
    }
}

const cKeyIndicatorAnimation = new CKeyIndicatorAnimation();
cKeyIndicatorAnimation.init();

// Класс для кнопки раскрытия мобильного меню
class CMobileMenu {
    init() {
        this.$mobileMenuBtn = $(".js-menu-mobile-btn");
        this.$menuList = $(".header-menu__item--list");
        this.$mobileMenuBtn.on("click", $.proxy(this, "toggleClassActive"));
    }

    toggleClassActive() {
        this.$menuList.toggleClass("is-active");

        if (this.$menuList.hasClass("is-active")) {
            this.$menuList.slideDown(200);
        } else {
            this.$menuList.slideUp(200);
        }
    }
}

const cMobileMenu = new CMobileMenu();
cMobileMenu.init();

// Маски для feedback
$(document).ready(() => {
    $(".feedback-telephone").each((i, elem) => {
        $(elem).inputmask({
            mask: "+7(999) 999-9999",
            showMaskOnHover: false,
            showMaskOnFocus: true
        });
    });

    if ($(".block-feedback").length) {
        const emailInput = document.querySelector('#feedbackEmail');

        maskInput({
            inputElement: emailInput,
            mask: emailMask
        })
    }
});

function anchorLinks(event) {
    const $elem = $(event.currentTarget);
    const $anchorLinks = $elem.data("anchor-link");
    const $anchors = $("[data-anchor]");

    const $targetScroll = $anchors
        .filter((i, elem) => {
            return $(elem).data("anchor") === $anchorLinks;
        })
        .offset().top;

    $("html, body").animate({scrollTop: $targetScroll}, 500);
}

$("[data-anchor-link]").click(function (event) {
    anchorLinks(event);
});

$(document).ready(() => {
    if (window.location.hash.length) {
        const hash = window.location.hash;
        const $anchors = $("[data-anchor]");

        const $targetScroll = $anchors
            .filter(function (index) {
                return $(this).data("anchor") === hash;
            })
            .offset().top;

        $("html, body").animate({scrollTop: $targetScroll}, 500);
    }
});

// Формы обратной связи - placeholder
(function ($) {
    $.fn.phAnim = function (options) {
        var settings = $.extend({}, options),
            label,
            ph;

        function getLabel(input) {
            return $(input)
                .parent()
                .find("label");
        }

        return this.each(function () {
            if (getLabel($(this)).length == 0) {
                if ($(this).attr("placeholder") != undefined) {
                    ph = $(this).attr("placeholder");
                    $(this).attr("placeholder", "");

                    $(this)
                        .parent()
                        .prepend(
                            "<label for=" + $(this).attr("id") + ">" + ph + "</label>"
                        );
                }
            } else {
                $(this).attr("placeholder", "");

                if (getLabel($(this)).attr("for") == undefined) {
                    getLabel($(this)).attr("for", $(this).attr("id"));
                }
            }

            $(this)
                .on("focus", function () {
                    label = getLabel($(this));
                    label.addClass("active focusIn");
                })
                .on("focusout", function () {
                    if ($(this).val() == "") {
                        label.removeClass("active");
                    }
                    label.removeClass("focusIn");
                });
        });
    };
})(jQuery);

$(document).ready(function () {
    $(".custom-input input").phAnim();
    $(".custom-input textarea").phAnim();
});

//Капча
(function ($) {
    "use strict";

    $.fn.captcha = function (param) {
        var params = $.extend(
            {
                idCaptchaText: "captchaText",
                idCaptchaInput: "captchaInput",
                class: ""
            },
            param
        );

        var submit = $(this).find("input[type=submit]");
        submit.attr("disabled", "disabled");

        $(".form-feedback").each((i, elem) => {
            if ($(elem).find(".captcha-wrapper").length) {
                return false;
            } else {
                $(
                    '<div class="captcha-wrapper"><label id="' +
                    params.idCaptchaText +
                    '"></label> <input type="number" id="' +
                    params.idCaptchaInput +
                    '" aria-label="Captcha Input" type="text" required></div>'
                ).insertBefore(".button-primary");
            }
        });

        var label = this.find("#" + params.idCaptchaText);
        var input = this.find("#" + params.idCaptchaInput);

        var rndmNr1 = Math.floor(Math.random() * 10),
            rndmNr2 = Math.floor(Math.random() * 10),
            totalNr = rndmNr1 + rndmNr2;

        $(label).text(rndmNr1 + " + " + rndmNr2 + " =");

        $(input).keyup(function () {
            const $checkBox = $(this)
                .closest(".js-feedback")
                .find(".checkbox-agree");

            if ($(this).val() == totalNr && $checkBox.prop("checked"))
                submit.removeAttr("disabled").addClass(params.class);
            else submit.attr("disabled", "disabled").removeClass(params.class);
        });

        $(".checkbox-agree").click(function () {
            const $captha = $(this)
                .closest(".js-feedback")
                .find("#customCaptchaInputId");

            if ($captha.val() == totalNr && $(this).prop("checked"))
                submit.removeAttr("disabled").addClass(params.class);
            else submit.attr("disabled", "disabled").removeClass(params.class);
        });
        return this;
    };
})(jQuery);

$(document).ready(function () {
    $(".js-feedback").each((i, elem) => {
        $(elem).captcha({
            idCaptchaText: "customCaptchaTextId",
            idCaptchaInput: "customCaptchaInputId",
            class: "button-primary"
        });
    });
});

$(window).scroll(function () {
    const $listParent = $(".is-current").closest(".nav-bar__list--lvl2");

    if (
        $listParent.length &&
        !$listParent.hasClass("is-open") &&
        $listParent.hasClass("is-close")
    ) {
        $listParent.addClass("is-open");
        $listParent.removeClass("is-close");
        $listParent.slideDown();
    }

    if (!$listParent.length && !$listParent.hasClass("is-close")) {
        $(".nav-bar__list--lvl2").addClass("is-close");
        $(".nav-bar__list--lvl2").removeClass("is-open");
        $(".nav-bar__list--lvl2").slideUp();
    }
});

// Присвоение класса '.browser-IE' тегу html, для стилей под IE11
if (platform.name == "IE") {
    $("html").addClass("browser-ie");
}

// Обработка отправки писем
const $form = $(".js-feedback");
$form.each((i, elem) => {
    $(elem).submit(e => {
        e.preventDefault();
        $.post({
            url: "/api/mail/send",
            dataType: 'json',
            contentType: 'application/json',
            data: $(elem).serializeJSON(),
            success: () => {
                $(".feedback-send__content").html("Сообщение успешно отправлено");
                $(".feedback-send").slideDown();
                $(elem)
                    .find("input:not(.feedback__button)")
                    .val("");
                $(elem)
                    .find("textarea")
                    .val("");
                $(elem)
                    .find("label")
                    .removeClass("active");

                if (
                    $(".popup-wrapper").hasClass("is-visible") &&
                    $(".lock-page").hasClass("is-active")
                ) {
                    $(".popup-wrapper").removeClass("is-visible");
                    $(".lock-page").removeClass("is-active");
                }

                setTimeout(() => {
                    $(".feedback-send").slideUp();
                }, 5000);
            },

            error: () => {
                $(".feedback-send__content").html("При отправке произошла ошибка");
                $(".feedback-send").slideDown();

                if (
                    $(".popup-wrapper").hasClass("is-visible") &&
                    $(".lock-page").hasClass("is-active")
                ) {
                    $(".popup-wrapper").removeClass("is-visible");
                    $(".lock-page").removeClass("is-active");
                }

                setTimeout(() => {
                    $(".feedback-send").slideUp();
                }, 5000);
            }
        });
    });
});

$(document).ready(function () {
    $(".nav-bar__list--lvl2").addClass("is-close");

    //down
    $(".wp-zone").waypoint(
        function (direction) {
            if (direction === "down") {
                $(".nav-link").removeClass("is-current");

                var selector = ".nav-link[href='#" + this.element.id + "']";
                $(selector).addClass("is-current");
            }
        },
        {
            offset: "5%"
        }
    );

    //up
    $(".wp-zone").waypoint(
        function (direction) {
            if (direction === "up") {
                $(".nav-link").removeClass("is-current");

                var selector = ".nav-link[href='#" + this.element.id + "']";
                $(selector).addClass("is-current");
            }
        },
        {
            offset: "-5%"
        }
    );
});

new Swiper(".swiper-container", {
    direction: "horizontal",
    loop: true,
    speed: 600,
    autoplay: {
        delay: 2000
    },

    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
    }
});

new Swiper(".swiper-container-cases", {
    direction: "horizontal",
    slidesPerView: 1,
    loop: true,
    loopedSlides: 11,
    spaceBetween: 200,
    speed: 600,
    simulateTouch: false,

    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
    breakpoints: {
        1200: {
            simulateTouch: true,
        },

        900: {
            spaceBetween: 30,
        },

        580: {
            spaceBetween: 10,
        }
    },
});